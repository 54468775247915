<template>
  <div style="padding: 15px;"
  >
    <el-row class="content_row" :gutter="15"
    >
      <el-col :md="{span:10,offset:14}">
        <InputSearchUi label="搜索"
         style="float: right;"
        @searchCond="searchCond"
        ></InputSearchUi>
      </el-col>
      <el-col
      v-for="userListItem in userList"
      :md="{span:6}"
      :xs="{span:24,offset:0}"
      >
      <div v-for="userItem in userListItem.userListItem">
        <transition name="el-fade-in" :appear="true">
          <el-row>
            <el-col
            :xs="{span:24}"
            :md="{span:24}"
            >
             <userInfoCard :restUser="restUser" :user="userItem" style="margin-bottom: 15px;"></userInfoCard>
            </el-col>
          </el-row>
          </transition>
        </div>
      </el-col>
    </el-row>
    <div
    style="height: 100px;float:left;width: 100%;">
        <span style="display: none;">{{disabled}}</span>
        <h4 v-if="noMore && !loading"style="color:#BABE99;text-align: center;">没有更多了哦！</h4>
        <div v-loading="loading" style="margin-top: 20px;">
        </div>
    </div>
    <div
    style="height: 450px;"
    v-if="loading || noMore"
    >
    </div>
  </div>
</template>
<script>
import InputSearchUi from '@/components/ui/InputSearchUi'
import {getUserApi} from '@/api/user'
import {getMyAttentionApi} from '@/api/relative'
import UserInfoCard from '../details/UserInfoCard.vue'
export default{
  name:'MyAttention',
  components: {
    UserInfoCard,
    InputSearchUi
  },
  created() {
    this.load()
  },
  data(){
    return{
      dataList:[],
      totalPage:undefined,
      currentPage:undefined,
      totalNumber:undefined,
      loading:true,
      userList:[
        {
          userListItem:[],
        },{
          userListItem:[],
        },{
          userListItem:[],
        },{
          userListItem:[],
        }
      ],
      query:{
        pageNum:1,
        pageSize:12,
        creator:this.$route.query.username,
      },
    }
  },
  computed:{
    noMore() {
        return this.totalNumber < this.query.pageSize || this.totalPage===this.currentPage;
    },
    disabled() {
      this.$emit("changeDisabled",this.loading || this.noMore)
      return this.loading || this.noMore
    },
  },
  methods:{
    restUser(username){
      this.$emit("restUser",username)
    },
    load() {
        this.loading=true
        this.getMyAttention()
    },
    restBlogList(){
      this.userList=[
        {
          userListItem:[],
        },{
          userListItem:[],
        },{
          userListItem:[],
        },{
          userListItem:[],
        }
      ]
    },
    searchCond(condition){
      this.restBlogList()
      this.query.pageNum=1
      this.query.pageSize=12
      this.query.condition=condition
      this.load()
    },
    async getMyAttention(){
     const res = await getMyAttentionApi(this.query)
     const userListAll=res.data.data.list;
     this.totalNumber=res.data.data.list.length;
     let  i=0;
     for( i=0;i<userListAll.length;++i){
       this.userList[i%4].userListItem.push(userListAll[i]);
     }
     this.loading=false;
     this.query.pageNum++;
     this.totalPage=res.data.data.pages
     this.currentPage=res.data.data.pageNum
    },

  }
}
</script>

<style scoped>
  .content_row{
    float: left;
    width: 100%;
  }
</style>
